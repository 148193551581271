import React from "react";
import { RxPerson } from "react-icons/rx";
import { useStateContext } from "../contexts/ContextProvider";
import { Footer } from "../components";
import axios from "axios";

const Setting = () => {
  const { shared_info, screenSize, userProfile } = useStateContext();
  const baseURL = shared_info.baseURL;

  const deleteProfile = () => {
    if (window.confirm("Delete my profile and all game records?")) {
      axios
        .post(`${baseURL}/delete`, {
          id: userProfile["id"],
        })
        .then((response) => {
          if (response.status === 200 && response.data["success"]) {
            window.open("about:blank", "_self");
            window.close();
          } else
            alert(
              "Failed! Please try again later or your profile is not exist!"
            );
        });
    }
  };

  return (
    <div className={screenSize >= 900 ? "mt-0" : "mt-16"}>
      <div className="flex flex-wrap lg:flex-nowrap justify-center">
        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          <div className="bg-white h-38 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-128 m-2 p-4 pt-5 rounded-2xl ">
            <RxPerson />
            {userProfile && (
              <p className="font-bold text-xl mt-1 text-center">
                {userProfile["name"]}
              </p>
            )}
            <p className="text-sm  mt-2 text-center">About you</p>
          </div>
        </div>
      </div>

      <div className="p-5">
        <p className="mb-3 font-bold text-black-500 dark:text-gray-400">
          Please read our terms and privacy notice:
        </p>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
          <p className="mb-3 text-gray-500 dark:text-gray-400">
            By engaging in this game, you hereby consent to the utilization of
            your IP address as a means of identification. Other players in the
            same network with you may share the same data.
          </p>
          <p className="mb-3 text-gray-500 dark:text-gray-400">
            This game is designed to be anonymous, and as such, your nickname is
            randomly generated when visit this website first time. In the event
            that you alter your IP address, a new anonymous identity will be
            assigned to you.
          </p>
          <p className="mb-3 text-gray-500 dark:text-gray-400">
            We suggest to use a private network if you want to keep your game
            records alone. If you want another nick name, the only way is
            deleting current profile and reload this game to create a new one.
          </p>
        </div>
        <p className="mb-3 text-gray-500 dark:text-gray-400">
          If you disagree with above, you can click the "Delete Account" button
          below. Please notice that you may delete others' data under a public
          network. Thank you so much for choosing this game!
        </p>
        <hr />
      </div>

      <h2 className="text-center font-bold">
        After deletion, this web page will be closed.
      </h2>
      <div className="flex justify-center">
        <button
          type="button"
          onClick={deleteProfile}
          className="mt-5 text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
        >
          Delete My Profile
        </button>
      </div>

      <Footer />
    </div>
  );
};

export default Setting;
