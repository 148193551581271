import React, { useEffect } from "react";
import { TfiCup } from "react-icons/tfi";
import { useStateContext } from "../contexts/ContextProvider";
import { Footer } from "../components";
import axios from "axios";

const RankR = () => {
  const { shared_info, screenSize, statRankR, setStatRankR } =
    useStateContext();
  const baseURL = shared_info.baseURL;

  useEffect(() => {
    // console.log("[RE-2] Init Users data");
    axios.get(`${baseURL}/region`).then((response) => {
      // console.log("[RE-2] Get latest stat from server...");
      if (response.data["success"]) {
        setStatRankR(response.data["data"]);
        // console.log("[RE-2] Stat has been updated.", response.data["data"]);
      }
    });
  }, [baseURL, setStatRankR]);

  return (
    <div className={screenSize >= 900 ? "mt-0" : "mt-16"}>
      <div className="flex flex-wrap lg:flex-nowrap justify-center">
        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          <div className="bg-white h-38 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-128 m-2 p-4 pt-5 rounded-2xl ">
            <TfiCup />
            {statRankR && (
              <p className="font-bold text-xl mt-1 text-center">
                {statRankR[0]["city"]}
              </p>
            )}
            <p className="text-sm  mt-2 text-center">Winner of Region</p>
          </div>
        </div>
      </div>

      <div className="flex w-full justify-center">
        <div className="w-10/12 relative overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Rank
                </th>
                <th scope="col" className="px-6 py-3">
                  Region
                </th>
                <th scope="col" className="px-6 py-3">
                  Total Clicks
                </th>
                <th scope="col" className="px-6 py-3">
                  Rescue
                </th>
                <th scope="col" className="px-6 py-3">
                  Abort
                </th>
                <th scope="col" className="px-6 py-3">
                  Contributors
                </th>
              </tr>
            </thead>
            <tbody>
              {statRankR &&
                statRankR.map((val, index) => {
                  return (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {index + 1}
                      </th>
                      <td className="px-6 py-4">{val.city}</td>
                      <td className="px-6 py-4">{val.total_counts_t}</td>
                      <td className="px-6 py-4">{val.total_counts_a}</td>
                      <td className="px-6 py-4">{val.total_counts_b}</td>
                      <td className="px-6 py-4">{val.total_users}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RankR;
