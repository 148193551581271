import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Navbar, Sidebar } from "./components";
import { Planet, Rank, RankB, RankR, RankC, Setting } from "./pages";

import { useStateContext } from "./contexts/ContextProvider";
import "./App.css";

import ipInt from "ip-to-int";
import {
  uniqueNamesGenerator,
  adjectives,
  colors,
  animals,
} from "unique-names-generator";
import axios from "axios";

function App() {
  const {
    shared_info,
    activeMenu,
    userProfile,
    setUserProfile,
    setTotalCounts,
  } = useStateContext();

  const baseURL = shared_info.baseURL;
  const ipInitURL = shared_info.ipInitURL;

  useEffect(() => {
    // console.log(shared_info.baseURL);
    async function getIP() {
      const response = await fetch(ipInitURL);
      return await response.json();
    }
    getIP().then((data) => {
      const ipId = ipInt(data["ip"]).toInt();
      // console.log("[R1] Read IP info:", data["ip"], ipId);

      axios
        .post(`${baseURL}/user`, {
          id: ipId,
        })
        .then((response) => {
          // console.log(response.data);
          if (response.data["success"]) {
            // console.log("[R1-2] Set User profile...", response.data["data"]);
            setUserProfile(response.data["data"]);
          } else {
            axios
              .get(`https://ipinfo.io/${data["ip"]}`, {
                params: { token: "5d04b583bbd135" },
              })
              .then((response) => {
                // console.log(response.data);
                let region = "Unknown";
                let country = "Unknown";

                if (response.status === 200) {
                  region =
                    response.data["city"] + ", " + response.data["region"];
                  country = response.data["country"];
                }

                axios
                  .post(`${baseURL}/create`, {
                    id: ipId,
                    name: uniqueNamesGenerator({
                      dictionaries: [adjectives, colors, animals],
                    }),
                    acount: 0,
                    bcount: 0,
                    tcount: 0,
                    city: region,
                    country: country,
                    geohash: "qtr5",
                  })
                  .then((response) => {
                    // console.log("[R1-2] Create IP profile...", response.data);
                    if (response.data["success"]) {
                      axios
                        .post(`${baseURL}/user`, {
                          id: ipId,
                        })
                        .then((response) => {
                          // console.log(response.data);
                          if (response.data["success"]) {
                            // console.log("[R1-2] Set User profile...");
                            setUserProfile(response.data["data"]);
                          }
                        });
                    }
                  });
              });
          }
        });
    });
  }, [baseURL, ipInitURL, shared_info.baseURL, setUserProfile]);

  useEffect(() => {
    // console.log("[R1-3] Load User profile:", userProfile);
  }, [userProfile]);

  useEffect(() => {
    function getUpdatedStat() {
      axios.get(`${baseURL}/counts`).then((response) => {
        // console.log("[R5-total] Get latest stat from server...");
        if (response.data["success"]) {
          setTotalCounts(response.data["data"]);
          // console.log("[R5-total] Stat has been updated.");
        }
      });
    }

    // getUpdatedStatUser();
    getUpdatedStat();
    const interval = setInterval(() => {
      // getUpdatedStatUser();
      getUpdatedStat();
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [baseURL, setTotalCounts]);

  return (
    <div>
      <BrowserRouter>
        <div className="flex relative dark:bg-main-dark-bg">
          {activeMenu ? (
            <div className="w-64 fixed sidebar dark:bg-secondary-dark-bg bg-white">
              <Sidebar />
            </div>
          ) : (
            <div className="w-0 dark:bg-secondary-dark-bg">
              <Sidebar />
            </div>
          )}

          <div
            className={`dark:bg-main-bg bg-main-bg min-h-screen w-full ${
              activeMenu ? "md:ml-64" : " flex-2"
            }`}
          >
            <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full">
              <Navbar />
            </div>

            <div>
              <Routes>
                <Route path="/" element={<Planet />}></Route>
                <Route path="/planet" element={<Planet />}></Route>
                <Route path="/rank" element={<Rank />}></Route>
                <Route path="/rankb" element={<RankB />}></Route>
                <Route path="/rankr" element={<RankR />}></Route>
                <Route path="/rankc" element={<RankC />}></Route>
                <Route path="/setting" element={<Setting />}></Route>
              </Routes>
            </div>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
