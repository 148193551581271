import React, { useEffect } from "react";
import { TbClick } from "react-icons/tb";
import { BsLightbulb } from "react-icons/bs";
import { useStateContext } from "../contexts/ContextProvider";
import { Footer } from "../components";
import axios from "axios";

const RankB = () => {
  const {
    shared_info,
    screenSize,
    userProfile,
    totalCounts,
    statRankB,
    setStatRankB,
  } = useStateContext();
  const baseURL = shared_info.baseURL;

  useEffect(() => {
    // console.log("[RR-2] Init Users data");
    axios.get(`${baseURL}/usersb`).then((response) => {
      // console.log("[RR-2] Get latest stat from server...");
      if (response.data["success"]) {
        setStatRankB(response.data["data"]);
        // console.log("[RR-2] Stat has been updated.", response.data["data"]);
      }
    });
  }, [baseURL, setStatRankB]);

  return (
    <div className={screenSize >= 900 ? "mt-0" : "mt-16"}>
      <div className="flex flex-wrap lg:flex-nowrap justify-center">
        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          <div className="bg-white h-32 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56 m-2 p-4 pt-5 rounded-2xl ">
            <TbClick />
            {userProfile && (
              <p className="font-bold text-3xl mt-1 text-center">
                {parseInt(userProfile["bcount"], 10)}
              </p>
            )}
            <p className="text-sm  mt-2 text-center">All My Aborting Clicks</p>
          </div>
          <div className="bg-white h-32 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56 m-2 p-4 pt-5 rounded-2xl ">
            <BsLightbulb />
            {totalCounts && (
              <p className="font-bold text-3xl text-center mt-1">
                {parseInt(totalCounts["b"], 10)}
              </p>
            )}
            <p className="text-sm  mt-2 text-center">Total Aborting Clicks</p>
          </div>
        </div>
      </div>

      <div className="flex w-full justify-center">
        <div className="w-10/12 relative overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Rank
                </th>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Clicks
                </th>
                <th scope="col" className="px-6 py-3">
                  Region
                </th>
                <th scope="col" className="px-6 py-3">
                  Country
                </th>
              </tr>
            </thead>
            <tbody>
              {statRankB &&
                statRankB.map((val, index) => {
                  return (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {index + 1}
                      </th>
                      <td className="px-6 py-4">{val.name}</td>
                      <td className="px-6 py-4">{val.bcount}</td>
                      <td className="px-6 py-4">{val.city}</td>
                      <td className="px-6 py-4">{val.country}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RankB;
