import React, { createContext, useContext, useState } from "react";

const StateContext = createContext();

const shared_info = {
  baseURL: "https://click-planet.uc.r.appspot.com/api/v1",
  ipInitURL: "https://api.ipify.org/?format=json",
  baseMiles: 50000, // 500000000
  baseTank: 100000, // 1000000000
};

export const ContextProvider = ({ children }) => {
  const [screenSize, setScreenSize] = useState(undefined);
  const [activeMenu, setActiveMenu] = useState(true);
  const [userProfile, setUserProfile] = useState(undefined);
  const [totalCounts, setTotalCounts] = useState(undefined);
  const [statRankA, setStatRankA] = useState(undefined);
  const [statRankB, setStatRankB] = useState(undefined);
  const [statRankC, setStatRankC] = useState(undefined);
  const [statRankR, setStatRankR] = useState(undefined);
  const [statusMascot, setStatusMascot] = useState(true);

  return (
    <StateContext.Provider
      value={{
        shared_info,
        userProfile,
        setUserProfile,
        activeMenu,
        setActiveMenu,
        screenSize,
        setScreenSize,
        totalCounts,
        setTotalCounts,
        statRankA,
        setStatRankA,
        statRankB,
        setStatRankB,
        statRankR,
        setStatRankR,
        statRankC,
        setStatRankC,
        statusMascot,
        setStatusMascot,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
