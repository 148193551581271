import React, { useEffect } from "react";

import { TbClick } from "react-icons/tb";
import { BsPeople, BsLightbulb } from "react-icons/bs";
import { useStateContext } from "../contexts/ContextProvider";
import { Footer } from "../components";

import axios from "axios";

const Planet = () => {
  const {
    shared_info,
    screenSize,
    userProfile,
    setUserProfile,
    totalCounts,
    statusMascot,
    setStatusMascot,
  } = useStateContext();
  const baseURL = shared_info.baseURL;

  const baseMiles = shared_info.baseMiles; // 500000000
  const baseTank = shared_info.baseTank; // 1000000000

  useEffect(() => {
    if (totalCounts) {
      // console.log(totalCounts);
      if (
        baseMiles -
          parseInt(totalCounts["a"], 10) +
          parseInt(totalCounts["b"], 10) <=
        0
      ) {
        setStatusMascot(false);
      } else if (baseTank - parseInt(totalCounts["t"], 10) <= 0) {
        setStatusMascot(false);
      } else setStatusMascot(true);
    }
  }, [totalCounts, setStatusMascot, baseMiles, baseTank]);

  return (
    <div className={screenSize >= 900 ? "mt-0" : "mt-16"}>
      <div className="flex flex-wrap lg:flex-nowrap justify-center">
        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          <div className="bg-white h-32 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56 m-2 p-4 pt-5 rounded-2xl ">
            <TbClick />
            {userProfile && (
              <p className="font-bold text-3xl mt-1 text-center">
                {userProfile["tcount"]}
              </p>
            )}
            <p className="text-sm  mt-2 text-center">All My Clicks</p>
          </div>
          <div className="bg-white h-32 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56 m-2 p-4 pt-5 rounded-2xl ">
            <BsLightbulb />
            {totalCounts && (
              <p className="font-bold text-3xl text-center mt-1">
                {totalCounts["t"]}
              </p>
            )}
            <p className="text-sm  mt-2 text-center">Total Clicks</p>
          </div>
          <div className="bg-white h-32 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56 m-2 p-4 pt-5 rounded-2xl ">
            <BsPeople />
            {totalCounts && (
              <p className="font-bold text-3xl mt-1 text-center">
                {totalCounts["u"]}
              </p>
            )}
            <p className="text-sm  mt-2 text-center">Total Players</p>
          </div>
        </div>
      </div>
      {statusMascot && userProfile && (
        <div className="mt-5 flex w-full justify-center">
          <button
            type="button"
            onClick={() => {
              axios
                .post(`${baseURL}/usera`, {
                  id: userProfile["id"],
                })
                .then((response) => {
                  // console.log("[R2-1] Made a rescue:", response.data);
                  axios
                    .post(`${baseURL}/user`, {
                      id: userProfile["id"],
                    })
                    .then((response) => {
                      // console.log("[R2-2] Update user data:", response.data);
                      setUserProfile(response.data["data"]);
                    });
                });
            }}
            className="text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-medium text-2xl rounded-lg px-10 py-5 text-center mr-2 mb-2"
          >
            Rescue
          </button>

          <button
            type="button"
            onClick={() => {
              axios
                .post(`${baseURL}/userb`, {
                  id: userProfile["id"],
                })
                .then((response) => {
                  // console.log("[R2-1] Made an Abort:", response.data);
                  axios
                    .post(`${baseURL}/user`, {
                      id: userProfile["id"],
                    })
                    .then((response) => {
                      // console.log("[R2-2] Update user data:", response.data);
                      setUserProfile(response.data["data"]);
                    });
                });
            }}
            className="ml-2 text-white bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium text-2xl rounded-lg px-10 py-5 text-center mr-2 mb-2"
          >
            Abort!
          </button>
        </div>
      )}

      <div className="mt-5 flex w-full justify-center">
        <p className="text-xl w-10/12">
          "NASAA sent Bella (a great puppy astronaut) to deep space, Bella has
          discovered many new things which can save Earth's environment and now
          want to come back! But some people said Bella may carry deadly virus
          from space."{" "}
          <span className="font-semibold italic underline text-xl decoration-green-500">
            Press 'Rescue' to help Bella move one step closer to Earth!
          </span>
          {" OR "}
          <span className="font-semibold italic underline text-xl decoration-red-500">
            Press 'Abort!' to force Bella move one step farther from Earth!
          </span>{" "}
          Please notice that each pressing will send a command to Bella and cost
          her one gallon energy, which can help her move one mile to any
          direction. DO YOU WISH BELLA COME BACK?
        </p>
      </div>

      <p className="text-center">* data will be updated every 5 seconds.</p>
      <div className="mt-5 flex w-full">
        {totalCounts && (
          <div className="p-5 w-full text-2xl font-normal leading-normal text-gray-900 dark:text-white">
            Bella is{" "}
            <span className="font-semibold italic underline text-3xl decoration-blue-500">
              {baseMiles -
                parseInt(totalCounts["a"], 10) +
                parseInt(totalCounts["b"], 10)}
            </span>{" "}
            miles away from Earth. She has used{" "}
            <span className="font-semibold italic underline text-3xl decoration-red-500">
              {totalCounts["t"]}
            </span>{" "}
            gallons energy and only{" "}
            <span className="font-semibold italic underline text-3xl decoration-green-500">
              {baseTank - parseInt(totalCounts["t"], 10)}
            </span>{" "}
            gallons remaining.
            {parseInt(totalCounts["a"], 10) - parseInt(totalCounts["b"], 10) >=
              0 &&
              statusMascot && (
                <p className="p-5 w-full font-normal leading-normal text-gray-900 dark:text-white">
                  Compared to starting position, she moved{" "}
                  <span className="font-semibold underline text-3xl">
                    closer to
                  </span>{" "}
                  Earth by{" "}
                  <span className="font-semibold underline text-3xl">
                    {parseInt(totalCounts["a"], 10) -
                      parseInt(totalCounts["b"], 10)}
                  </span>{" "}
                  miles. People on Earth want her come back. She will survive if
                  this trend continues...
                </p>
              )}
            {baseMiles -
              parseInt(totalCounts["a"], 10) +
              parseInt(totalCounts["b"], 10) <=
              0 &&
              !statusMascot && (
                <div>
                  <p className="p-5 text-center text-3xl w-full font-semibold leading-normal text-green-600 dark:text-white">
                    Good news! Bella backed to Earth safely!
                  </p>
                  <p className="p-5 w-full font-normal leading-normal dark:text-white">
                    NASAA decided to send another astronaut to explore
                    universe... Please come back next season!
                  </p>
                </div>
              )}
            {baseMiles -
              parseInt(totalCounts["a"], 10) +
              parseInt(totalCounts["b"], 10) >
              0 &&
              !statusMascot && (
                <div>
                  <p className="p-5 text-center text-3xl w-full font-semibold leading-normal text-red-600 dark:text-white">
                    Sadly, Bella will never come back...
                  </p>
                  <p className="p-5 w-full font-normal leading-normal dark:text-white">
                    NASAA decided to send another astronaut to explore
                    universe... Please come back next season!
                  </p>
                </div>
              )}
            {parseInt(totalCounts["a"], 10) - parseInt(totalCounts["b"], 10) <
              0 &&
              statusMascot && (
                <p className="p-5 w-full font-normal leading-normal text-gray-900 dark:text-white">
                  Compared to starting position, he moved{" "}
                  <span className="underline font-semibold text-3xl">
                    farther to
                  </span>{" "}
                  Earth by{" "}
                  <span className="font-semibold underline text-3xl">
                    {parseInt(totalCounts["b"], 10) -
                      parseInt(totalCounts["a"], 10)}
                  </span>{" "}
                  miles. People on Earth do NOT want her come back. She will die
                  if this trend continues...
                </p>
              )}
          </div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default Planet;
